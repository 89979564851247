<ng-content></ng-content>
<svg
  *ngIf="svgRect$ | async as svgRect"
  #svg
  [style.left.px]="svgRect.x"
  [style.top.px]="svgRect.y"
  [style.width.px]="svgRect.width"
  [style.height.px]="svgRect.height"
  viewBox="0 0 1 1"
  preserveAspectRatio="none"
  [ngClass]="{ editable: editable }"
>
  <g
    *ngFor="let hotspot of hotspots"
    hotspot
    [draggable]="editable"
    [editable]="editable"
    [coords]="hotspot.coords"
    (coordsChange)="handleHotspotCoordsChange($event, hotspot)"
    [hidden]="!editable"
    [selected]="hotspot === selectedHotspot"
    [translate]="translations.get(hotspot)"
    (click)="handleHotspotClick($event, hotspot)"
    (drag)="handleHotspotDrag($event, hotspot)"
    (dragComplete)="handleHotspotDragComplete(hotspot)"
    [svgSize]="svgRect"
  ></g>
  
  <g
    *ngIf="newHotspotCoords"
    hotspot
    [coords]="newHotspotCoords"
    [previewPoint]="newHotspotPreviewPoint"
    [svgSize]="svgRect"
  ></g>
</svg>