import { Injectable } from "@angular/core";
import { CommonUtils, LocalStorageUtils } from "../core/utils";
import { ActivatedRoute, Router } from "@angular/router";
import { environment } from "src/environments/environment";
import { Slide } from "../create-course/create-course.interfaces";
import { Subject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class CourseService {
  courseBuilderSteps: Slide = {};
  deletingAssets: Subject<any> = new Subject();
  globalConfigs: any = {};
  styleObject!: any;

  constructor() { }

  getParamByName(activateRoute: ActivatedRoute, name: string): string {
    return activateRoute.snapshot.paramMap?.get(name) || "";
  }

  getQueryParamByName(activateRoute: ActivatedRoute, name: string): string {
    let result = "";
    activateRoute.queryParams.subscribe((params) => {
      result = params[name];
    });
    return result;
  }

  isEditDisabled() {
    return LocalStorageUtils.retrieveFromLocalStorage("courses") ? false : true;
  }

  generateCourseId(): number {
    const courseId = LocalStorageUtils.generateCourseId() || 0;
    return Number(courseId);
  }

  getCoursesFromLocalStorage(): any[] {
    return LocalStorageUtils.retrieveFromLocalStorage("courses") || [];
  }

  getCourseByIdFromLocalStorage(id: number): any {
    const courses: any[] =
      LocalStorageUtils.retrieveFromLocalStorage("courses") || [];
    return courses.find((course) => course.id === id);
  }

  getStyle(client: string = "TBS") {
    const authData = LocalStorageUtils.retrieveFromLocalStorage("authData");
    if (authData != null) {
      return authData.client?.clientCss;
    } else return environment.clientCss[client];
  }

  applyStyles(client: string = "TBS"): void {
    const styleObject: any = this.getStyle(client);

    if (styleObject) {
      this.styleObject = styleObject;
    }

    const styleProperties: string[] = [
      "congratulationColor",
      "bgColorDelBtn",
      "borderColor",
      "primaryColor",
      "secondaryColor",
      "thirdColor",
      "primaryTextColor",
      "mainButtonTextColor",
      "secondaryTextColor",
      "PageTitleBottomColor",
      "viewPrevNextButton",
      "viewBackground",
      "cardBoxShadowColor",
      "logoColor",
    ];

    styleProperties.forEach((property) => {
      if (!styleObject) {
        return;
      }

      var style = styleObject[property];
      if (property == "viewBackground" && 
        (style?.includes("/uploads") || style?.includes("amazonaws"))
      ) {
        style = `url(${CommonUtils.getFileUrl(style)})`;
      }

      document.documentElement.style.setProperty(`--${property}`, style);
    });
  }


  saveCourseObject(data: any): void {
    this.courseBuilderSteps = data;
  }

  saveGloalConfigs(data: any): void {
    this.globalConfigs = data;
  }

  retrieveGloalConfigs() {
    return this.globalConfigs;
  }

  retrieveCourseObject(): Slide | null {
    if (this.courseBuilderSteps) {
      return this.courseBuilderSteps;
    } else {
      console.error(`Error retrieving course from service`);
      return null;
    }
  }

  updateCourseObject(object: any, key?: string) {
    if (object && key) {
      this.courseBuilderSteps[key as keyof Slide] = object;
    } else if (object) {
      this.courseBuilderSteps = object;
    } else {
      console.error(`Error update course from service`);
    }
  }

  deleteCourseBuilderSteps() {
    this.courseBuilderSteps = {};
  }

  getDefaultThemeColor(key: string) {
    if (this.styleObject && key) {
      return this.styleObject[key];
    }
    return "white";
  }

  removingAssetsFromStepTwo(assetDetailsObj: any) {
    this.deletingAssets.next(assetDetailsObj)
  }
}
