<section class="courses-listing-slide">
  <h4 nz-typography>  {{ 'COURSES' | translate }} - {{ getDisplayText(category) }} </h4>
  <div class="table-container">
    <nz-table #coursesTable 
      [nzData]="courses" 
      [nzShowPagination]="false"
      [nzFrontPagination]="false">
      <thead>
        <tr>
          <!-- Loop for columns excluding actions -->
          <th
            *ngFor="let column of tableHeaders">
            {{ column }}
          </th>
          <!-- Action column without sorting and filtering -->
          <th>{{ 'ACTIONS' | translate }}</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let data of coursesTable.data">
          <td *ngFor="let column of listOfColumns">
            {{ getColumnValue(data, column.name) }}
          </td>
          <td>
            <a
              [routerLink]="['/view-courses', data.id]"
              class="courseButtonIcon"
              nz-button
              nzType="primary"
              title="{{ 'VIEW_PARTICULAR_COURSE' | translate }}"
              *ngIf="data.totalSlideCount"
            >
              <span nz-icon nzType="eye" nzTheme="outline"></span>
            </a>
            <a
              (click)="editCourse(data)"
              class="courseButtonIcon"
              title="{{ 'EDIT_PARTICULAR_COURSE' | translate }}"
              nz-button
              nzType="primary"
              *ngIf="checkCompletedStatus(data)"
            >
              <span nz-icon nzType="edit" nzTheme="outline"></span>
            </a>
              <nz-modal
                [(nzVisible)]="isVisible"
                nzTitle="EDIT AN EVENT"
                nzOkText="OK"
                nzCancelText="CANCEL"
                (nzOnOk)="editCourseFromModal()"
                (nzOnCancel)="handleCancel()"
              >
                <ng-container *nzModalContent>
                  <p>Enter “0” to modify global configurations or enter the specific Page # you want to edit.</p>
                  <p>This event contains {{editPageSize}} pages.</p>
                  <input type="number"      
                    id="editPageNumber"
                    name="editPageNumber"
                    [value]="editPageNumber"
                    (input)="editPageNumber = parseInputValue($event)"
                    (focus)="onInputFocus($event)" 
                    (click)="onInputFocus($event)"
                    min="0" 
                    max="{{editPageSize}}">
                </ng-container>
              </nz-modal>
            <a
              (click)="createNextVersion(data)"
              class="courseButtonIcon"
              title="{{ 'DUPLICATE_PARTICULAR_COURSE' | translate }}"
              nz-button
              nzType="primary"
              *ngIf="checkForDuplicte(data)"
            >
              <span nz-icon nzType="copy" nzTheme="outline"></span>
            </a>
            <a
              (click)="createCopy(data)"
              class="courseButtonIcon"
              title="{{ 'COPY_PARTICULAR_COURSE' | translate }}"
              nz-button
              nzType="primary"
            >
              <span nz-icon nzType="file-add" nzTheme="outline"></span>
            </a>
            <a
            (click)="duplicateToLive(data)"
            class="duplicateToLiveIcon"
            title="{{ 'DUPLICATE_TO_LIVE_PARTICULAR_COURSE' | translate }}"
            nz-button
            nzType="primary"
            *ngIf="!production"
          >
            <span nz-icon nzType="send" nzTheme="outline"></span>
          </a>
            <a
              (click)="deleteCourse(data.id)"
              title="{{ 'DELETE_PARTICULAR_COURSE' | translate }}"
              class="deleteBtn"
              nz-button
              nzType="primary"
              *ngIf="checkCompletedStatus(data)"
            >
              <span nz-icon nzType="delete" nzTheme="outline"></span>
            </a>
          </td>
        </tr>
      </tbody>
    </nz-table>
    <br />
    <nz-pagination
      [nzPageIndex]="meta.pagination.page"
      [nzTotal]="meta.pagination.total"
      nzShowSizeChanger
      [nzPageSizeOptions]="pageOptions"
      [nzPageSize]="meta.pagination.pageSize"
      (nzPageIndexChange)="handlePageIndexChange($event)"
      (nzPageSizeChange)="handlePageSizeChange($event)"
    ></nz-pagination>
  </div>
</section>
<div class="steps-footer">
  <button
    [routerLink]="['/']"
    nz-button
    nzType="primary"
    nzSize="large"
  >
    <span nz-icon nzType="left" nzTheme="outline"></span>
    {{ 'BACK' | translate }}
  </button>
</div>