import {
  Component,
  ContentChild,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  NgZone,
  OnDestroy,
  Output,
  ViewChild,
  afterNextRender,
} from "@angular/core";
import {
  combineLatest,
  distinctUntilChanged,
  filter,
  firstValueFrom,
  fromEvent,
  map,
  Observable,
  of,
  ReplaySubject,
  shareReplay,
  Subscription,
  switchMap,
} from "rxjs";
import { DraggableDirectiveEvent } from "../draggable.directive";
import { ImgHotspot } from "../img-hotspot";
import { ImgDirective } from "../img.directive";
import { environment } from "src/environments/environment";

type Size = { width: number; height: number };

@Component({
  selector: "app-hotspot-img",
  templateUrl: "./hotspot-img.component.html",
  styleUrls: ["./hotspot-img.component.css"],
})
export class HotspotImgComponent implements OnDestroy {
  @Input()
  hotspots: ImgHotspot[] = [];

  @Input()
  editable = false;

  @Output()
  hotspotsChange = new EventEmitter<ImgHotspot[]>();

  @Output()
  hotspotClick = new EventEmitter<ImgHotspot>();

  @Output()
  missedClick = new EventEmitter<{ count: number }>();

  @Input()
  selectedHotspot?: ImgHotspot;

  @Output()
  selectedHotspotChange = new EventEmitter<ImgHotspot | undefined>();

  @ContentChild(ImgDirective, { read: ElementRef })
  set image(value: ElementRef<HTMLImageElement> | undefined) {
    this.imageSubject.next(value?.nativeElement);
  }

  private imageSubject = new ReplaySubject<HTMLImageElement | undefined>(1);
  private image$ = this.imageSubject.pipe(distinctUntilChanged());
  private imageNaturalSize$: Observable<Size> = this.image$.pipe(
    filter((image) => image != null),
    switchMap((image) => {
      if (image == null) {
        throw new Error("image is null");
      }
      if (image.complete) {
        return of({ width: image.naturalWidth, height: image.naturalHeight });
      } else {
        return fromEvent(image, "load").pipe(
          map(() => ({
            width: image.naturalWidth,
            height: image.naturalHeight,
          })),
        );
      }
    }),
    distinctUntilChanged(),
  );
  private hostResizeObserver?: ResizeObserver;
  private hostSizeSubject = new ReplaySubject<Size>(1);
  private hostSize$ = this.hostSizeSubject.pipe(distinctUntilChanged());
  svgRect$ = combineLatest([
    this.imageNaturalSize$,
    this.hostSize$,
  ]).pipe(
    map(([imageSize, hostSize]) => {
      const imageRatio = imageSize.width / imageSize.height;
      const hostRatio = hostSize.width / hostSize.height;
      let width = hostSize.width;
      let height = hostSize.height;
      let x = 0;
      let y = 0;
      if (imageRatio < hostRatio) {
        width = hostSize.height * imageRatio;
        x = (hostSize.width - width) / 2;
      } else {
        height = hostSize.width / imageRatio;
        y = (hostSize.height - height) / 2;
      }
      return { x, y, width, height };
    }),
    shareReplay({ bufferSize: 1, refCount: true }),
  );

  private missedClickCount = 0;

  newHotspotCoords?: { x: number; y: number }[];
  translations = new Map<ImgHotspot, { x: number; y: number }>();
  newHotspotPreviewPoint?: { x: number; y: number };

  private _svg: ElementRef<SVGElement> | undefined;
  @ViewChild("svg")
  public set svg(v: ElementRef<SVGElement> | undefined) {
    this._svg = v;
    if (v != null) {
      this.handleSvgEvents();
    }
  }
  public get svg(): ElementRef<SVGElement> | undefined {
    return this._svg;
  }

  private isSingleClick = true;
  private subscriptions = new Subscription();
  private pointerMoveSubscription?: Subscription;

 constructor(private zone: NgZone, private host: ElementRef<HTMLElement>) {
    this.handleHostChange();
  }
  private handleHostChange() {
    this.hostResizeObserver = new ResizeObserver((entries) => {
      for (const entry of entries) {
        this.zone.run(() => {
          this.hostSizeSubject.next({
            width: (entry.target as any).offsetWidth,
            height: (entry.target as any).offsetHeight,
          });
        });
      }
    });
    this.hostResizeObserver.observe(this.host.nativeElement);
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
    this.pointerMoveSubscription?.unsubscribe();
    this.hostResizeObserver?.disconnect();
  }

  @HostListener("document:keydown.enter")
  public completeNewHotspot() {
    if (this.hotspots?.length < (environment?.hotspotsCount ?? 3)) {
      if (this.newHotspotCoords == null) {
        return;
      }

      const newHotspot: ImgHotspot = {
        coords: this.newHotspotCoords,
      };

      if (!this.hotspots) this.hotspots = [];

      this.hotspots?.push(newHotspot);
      this.hotspotsChange.emit(this.hotspots);

      this.newHotspotCoords = undefined;
      this.pointerMoveSubscription?.unsubscribe();
    }
  }

  @HostListener("document:keydown.escape", ["$event"])
  public cancelNewHotspot(event: Event) {
    event.stopImmediatePropagation();
    this.newHotspotCoords = undefined;
    this.pointerMoveSubscription?.unsubscribe();
  }

  @HostListener("document:keydown.delete")
  public deleteSelectedHotspot() {
    if (this.selectedHotspot == null || !this.editable) {
      return;
    }

    const index = this.hotspots?.indexOf(this.selectedHotspot);
    if (index >= 0) {
      this.hotspots.splice(index, 1);
      this.hotspotsChange.emit(this.hotspots);
    }

    this.selectedHotspot = undefined;
    this.selectedHotspotChange.emit(undefined);
  }

  handleHotspotClick(event: Event, hotspot: ImgHotspot) {
    event.stopImmediatePropagation();
    this.hotspotClick.emit(hotspot);
    this.selectedHotspot = hotspot;
    this.selectedHotspotChange.emit(hotspot);
  }
  async handleHotspotDrag(event: DraggableDirectiveEvent, hotspot: ImgHotspot) {
    const svgRect = await firstValueFrom(this.svgRect$);
    this.translations.set(hotspot, {
      x: event.totalX / svgRect.width,
      y: event.totalY / svgRect.height,
    });
  }

  handleHotspotDragComplete(hotspot: ImgHotspot) {
    const translation = this.translations.get(hotspot);
    if (translation == null) {
      return;
    }

    for (const coord of hotspot.coords) {
      coord.x += translation.x;
      coord.y += translation.y;
    }

    this.translations.delete(hotspot);
    this.hotspotsChange.emit(this.hotspots);
  }

  handleHotspotCoordsChange(
    coords: { x: number; y: number }[],
    hotspot: ImgHotspot,
  ) {
    hotspot.coords = coords;
    this.hotspotsChange.emit(this.hotspots);
  }

  private beginNewHotspot(coords: { x: number; y: number }) {
    this.newHotspotCoords = [coords];
    this.newHotspotPreviewPoint = { ...coords };
    if (
      this.pointerMoveSubscription == null ||
      this.pointerMoveSubscription.closed
    ) {
      this.pointerMoveSubscription = fromEvent(
        this.svg!.nativeElement,
        "pointermove",
      ).subscribe((event) => {
        this.previewNewHotspotPoint(event as PointerEvent);
      });
    }
  }

  private handleSvgClick(event: MouseEvent) {
    event.stopImmediatePropagation();
    event.preventDefault();
    this.isSingleClick = true;
    setTimeout(() => {
      if (this.isSingleClick) {
        this.handleSingleClick(event);
      }
    }, 50);
  }

  private handleSvgDblClick(event: MouseEvent) {
    this.isSingleClick = false;
    this.completeNewHotspot();
  }

  private appendPointToNewHotspot(coords: { x: number; y: number }) {
    if (this.newHotspotCoords == null) {
      return;
    }
    this.newHotspotCoords = [...this.newHotspotCoords, coords];
  }

  private previewNewHotspotPoint(event: MouseEvent) {
    if (this.newHotspotCoords == null) {
      return;
    }

    if (this.svg?.nativeElement == null) {
      throw new Error("svg element not found");
    }

    const width = this.svg.nativeElement.clientWidth;
    const height = this.svg.nativeElement.clientHeight;

    const coords = {
      x: event.offsetX / width,
      y: event.offsetY / height,
    };

    if (this.newHotspotPreviewPoint == null) {
      this.newHotspotPreviewPoint = coords;
    } else {
      this.newHotspotPreviewPoint.x = coords.x;
      this.newHotspotPreviewPoint.y = coords.y;
    }
  }

  private handleSingleClick(event: MouseEvent) {
    if (!this.editable) {
      this.missedClick.emit({ count: ++this.missedClickCount });
      return;
    }
    if (
      this.hotspots &&
      this.hotspots.length >= (environment?.hotspotsCount ?? 3)
    ) {
      return;
    }

    const naturalWidth = this.svg?.nativeElement.clientWidth;
    const naturalHeight = this.svg?.nativeElement.clientHeight;
    if (naturalWidth == null || naturalHeight == null) {
      return;
    }
    // fallback to layerX and layerY for Firefox
    const x =
      event.offsetX === 0 &&
      event.offsetY === 0 &&
      "layerX" in event &&
      Number(event.layerX) > 0
        ? Number(event.layerX)
        : event.offsetX;
    const y =
      event.offsetY === 0 &&
      event.offsetX === 0 &&
      "layerY" in event &&
      Number(event.layerY) > 0
        ? Number(event.layerY)
        : event.offsetY;
    const coords = {
      x: x / naturalWidth,
      y: y / naturalHeight,
    };
    if (this.newHotspotCoords == null) {
      this.beginNewHotspot(coords);
    } else {
      this.appendPointToNewHotspot(coords);
    }
  }

  private handleSvgEvents() {
    this.zone.run(() => {
      //setTimeout(() => {
        if (this.svg?.nativeElement == null) {
          throw new Error("svg element not found");
        }
        this.subscriptions.add(
          fromEvent(this.svg.nativeElement, "click").subscribe((event) => {
            this.handleSvgClick(event as PointerEvent);
          }),
        );
        this.subscriptions.add(
          fromEvent(this.svg.nativeElement, "dblclick").subscribe((event) => {
            this.handleSvgDblClick(event as PointerEvent);
          }),
        );
      //}, 600);
    });
  }
}
