<div [formGroup]="parentForm">
  <div class="card-display" nz-row [nzGutter]="[24, 24]">
    <div
      nz-col
      class="gutter-row"
      [nzXs]="24 / 1"
      [nzSm]="24 / 1"
      [nzMd]="selectedOption?.value !== 'L1-OP-4' ? 24 / 2 : 24 / 1"
      [nzLg]="selectedOption?.value !== 'L1-OP-4' ? 24 / 2 : 24 / 1"
    >
      <nz-form-item>
        <nz-form-label [nzRequired]="componentName == courseConst.STEP_TWO" nzFor="slideTitle">{{
          "SLIDE_TITLE" | translate
        }}</nz-form-label>
        <nz-form-control nzErrorTip="{{ 'ADD_LAYER_TITLE' | translate }}">
          <input
            nz-input
            formControlName="slideTitle"
            id="slideTitle"
            style="height: 60px"
          />
        </nz-form-control>
      </nz-form-item>
    </div>
    <div
      nz-col
      class="gutter-row"
      [nzXs]="24 / 1"
      [nzSm]="24 / 1"
      [nzMd]="24 / 2"
      [nzLg]="24 / 2"
      *ngIf="courseCategory !='quiz'"
    >
      <div>
        <nz-form-item>
          <nz-form-label>
            {{ assetConstantAudio.TITLE | translate }}
          </nz-form-label>
          <nz-alert
            nzType="warning"
            nzMessage="Instructions"
            [nzDescription]="descriptionTpl"
            nzShowIcon
          >
            <ng-template #descriptionTpl>
              <div>
                - {{ assetConstantAudio.MIN_SIZE | translate }}
                {{ assetConfigurationAudio.minSize }}
                {{ "IMAGE_SIZE_FORMAT" | translate }}
              </div>
              <div>
                - {{ assetConstantAudio.MAX_SIZE | translate }}
                {{ assetConfigurationAudio.maxSize }}
                {{ "IMAGE_SIZE_FORMAT" | translate }}
              </div>
              <div>
                - {{ assetConstantAudio.ALLOWABLE_TYPE | translate }}: [{{
                  assetConfigurationAudio.allowedFileTypes
                }}]
              </div>
              <div>
                - {{ assetConstantAudio.ALLOWABLE_COUNT | translate }}:
                {{ assetConfigurationAudio.maxFileCount }}
              </div>
            </ng-template>
            ></nz-alert
          >
          <nz-form-control>
            <nz-upload
              nzAccept="assetConfigurationAudio.allowedFileTypes"
              [nzRemove]="handleAudioRemove"
              [nzShowUploadList]="{ showRemoveIcon: true }"
              [nzBeforeUpload]="audioBeforeUpload"
              nzListType="picture"
              [(nzFileList)]="audioFileList"
              nzType="drag"
              [nzMultiple]="true"
              [nzDisabled]="
                audioFileList.length >= assetConfigurationAudio.maxFileCount
              "
              required
              *ngIf="!reloadingPreview"
            >
              <button
                nz-button
                type="button"
                nzType="primary"
                (click)="handleAudioUploadClick($event)"
                class="mlr-20"
                [class.disabled]="disableAudioUploadButton"
              >
                <span nz-icon nzType="upload"></span>
                {{ "SELECT_FILE" | translate }}
              </button>
            </nz-upload>
            <nz-form-label
              class="mt-2"
              *ngIf="
                audioFileList.length < assetConfigurationAudio.maxFileCount
              "
            >
              {{ "RECORD_CUSTOM_SOUND" | translate }}
            </nz-form-label>
            <audio class="audio-player-width" controls *ngIf="audioFileList[0]?.url" >
              <source [src]="audioFileList[0].url" type="audio/webm" />
            </audio>
            <div
              *ngIf="
                audioFileList.length < assetConfigurationAudio.maxFileCount
              "
              class="audio-container"
            >
              <app-audio-recording
                [audioValue]="audioFileList"
                (emitRecordedFile)="audioBeforeUpload($event)"
                [indexOfAudioEditor]="indexOfActiveContentEditor"
                [indexOfContentEditor]="indexOfContentEditor"
              >
              </app-audio-recording>
            </div>
          </nz-form-control>
        </nz-form-item>
      </div>
    </div>
  </div>

  <div class="headings">
    <h4 nz-typography>
      {{ "STEP_TWO_PART_1" | translate }} {{ totalParts }}
      <span class="font-size-20" nz-typography
        >- {{ subtitle[selectedOption?.value || "L1-0P-1"] }}
      </span>
      <span class="italic" nz-typography>*{{ "MANDATORY" | translate }}</span>
    </h4>
  </div>
  <div class="card-display">
    <div class="buttons-wrapper" nz-row [nzGutter]="[24, 24]">
      <div
        nz-col
        *ngIf="selectedOption?.value !== 'L1-OP-4'"
        class="gutter-row"
        [nzXs]="24 / 1"
        [nzSm]="24 / 1"
        [nzMd]="
          selectedOption?.value !== 'L1-OP-4' &&
          selectedOption?.value !== 'L1-OP-1'
            ? 24 / 2
            : 24 / 1
        "
        [nzLg]="
          selectedOption?.value !== 'L1-OP-4' &&
          selectedOption?.value !== 'L1-OP-1'
            ? 24 / 2
            : 24 / 1
        "
      >
        <nz-form-item>
          <nz-form-label nzRequired>{{
            "ENTER_TEXT" | translate
          }}</nz-form-label>
          <nz-alert
            nzType="warning"
            nzMessage="Instructions"
            [nzDescription]="descriptionTpl"
            nzShowIcon
          >
            <ng-template #descriptionTpl>
              <div>
                - Select your desired font size and family before typing.
              </div>
              <div>
                - Highlight a word/paragraph to set it to a different font.
              </div>
              <div>
                - When clicking/highlighting on a specific word, the selected word's font will be displayed in blue, as shown in the screenshot below.
              </div>
            </ng-template>
            ></nz-alert
          >
          <nz-form-control>
            <quill-editor
              class="quill-editor"
              formControlName="editor"
            ></quill-editor>
            <div *ngIf="validateText()" class="ant-form-explain color-red">
              {{ "TEXT_REQUIRED" | translate }}
            </div>
          </nz-form-control>
        </nz-form-item>
      </div>

      <div
        nz-col
        class="gutter-row"
        *ngIf="selectedOption?.value !== 'L1-OP-1'"
        [nzXs]="24 / 1"
        [nzSm]="24 / 1"
        [nzMd]="selectedOption?.value !== 'L1-OP-4' ? 24 / 2 : 24 / 1"
        [nzLg]="selectedOption?.value !== 'L1-OP-4' ? 24 / 2 : 24 / 1"
      >
        <div *ngIf="_uploadAssetContainer">
          <nz-form-item>
            <nz-form-label>
              <span class="color-red">*&nbsp; </span>
              {{ assetConstant.TITLE | translate }}
            </nz-form-label>
            <nz-alert
              nzType="warning"
              nzMessage="Instructions"
              [nzDescription]="descriptionTpl"
              nzShowIcon
            >
              <ng-template #descriptionTpl>
                <div *ngIf="assetType == supportedAsset.IMAGE">
                  - {{ assetConstant.MIN_ICON_SIZE | translate }}
                  {{ assetConfiguration.minSize }}
                  {{ "IMAGE_SIZE_FORMAT" | translate }}
                </div>
                <div>
                  - {{ assetConstant.MIN_SIZE | translate }}
                  {{ assetConfiguration.minSize }}
                  {{ "IMAGE_SIZE_FORMAT" | translate }}
                </div>
                <div>
                  - {{ assetConstant.MAX_SIZE | translate }}
                  {{ assetConfiguration.maxSize }}
                  {{ "IMAGE_SIZE_FORMAT" | translate }}
                </div>
                <div>
                  - {{ assetConstant.ALLOWABLE_TYPE | translate }}: [{{
                    assetConfiguration.allowedFileTypes
                  }}]
                </div>
                <div>
                  - {{ assetConstant.ALLOWABLE_COUNT | translate }}:
                  {{ assetConfiguration.maxFileCount }}
                </div>
              </ng-template>
              ></nz-alert
            >
            <nz-form-control>
              <nz-upload
                nzAccept="assetConfiguration.allowedFileTypes"
                [nzRemove]="handleImageRemove"
                [nzShowUploadList]="{ showRemoveIcon: true }"
                [nzBeforeUpload]="beforeUpload"
                nzListType="picture-card"
                [(nzFileList)]="fileList"
                nzType="drag"
                [nzMultiple]="true"
                [nzDisabled]="
                  fileList.length >= assetConfiguration.maxFileCount
                "
                required
                *ngIf="!reloadingPreview"
              >
                <button
                  nz-button
                  type="button"
                  nzType="primary"
                  (click)="handleUploadClick($event)"
                  class="mlr-20"
                  [class.disabled]="disableUploadButton"
                >
                  <span nz-icon nzType="upload"></span>
                  {{ "SELECT_FILE" | translate }}
                </button>
              </nz-upload>
              <div
                *ngIf="displayUploadValidation()"
                class="ant-form-explain color-red"
              >
                {{ assetConstant.ASSET_REQUIRED | translate }}
              </div>
            </nz-form-control>
          </nz-form-item>

          <nz-form-item *ngIf="this.selectedOption?.value == layerOpt.L1_L2_OP_3 && this.fileList.length == 1">
            <nz-form-label>
              <span class="color-red">*&nbsp; </span>
              {{ "MANAGE_HOTSPOTS" | translate }}
            </nz-form-label>
            <nz-form-control>
              <div *ngIf="displayUploadValidation()" class="ant-form-explain color-red">
                {{ assetConstant.HOTSPOTS_REQUIRED | translate }}
              </div>
          
              <div>
                <nz-form-label class="manage-hotspot-btn">
                  <button nz-button type="button" nzType="primary" class="mlr-20" (click)="showManageHotspotsDialog($event)">
                    {{ "MANAGE_HOTSPOTS" | translate }}
                  </button>
                </nz-form-label>
                
                <ul>
                  <li *ngFor="let hotspot of hotspots; let i = index">
                    {{hotspot?.data }}
                  </li>
                </ul>
              </div>
            </nz-form-control>
          </nz-form-item>

        </div>
        <div *ngIf="_addButtonContainer">
          <nz-form-item>
            <nz-form-label>
              <span class="color-red">*&nbsp; </span>
            </nz-form-label>
            <nz-alert
              nzType="warning"
              nzMessage="Instructions"
              [nzDescription]="descriptionTpl"
              nzShowIcon
            >
              <ng-template #descriptionTpl>
                <div>- {{ "MAXIMUM_NUMBER_OF_BUTTONS" | translate }} 8</div>
                <div>
                  - {{ "MAXIMUM_NUMBER_OF_CHARACTERS_BUTTONS" | translate }} 50
                </div>
                <div class="d-flex justify-content-between align-items-center">
                  <input
                    nz-input
                    placeholder="Enter a button name ..."
                    nzSize="default"
                    class="button-name-input"
                    [(ngModel)]="buttonName"
                    [ngModelOptions]="{ standalone: true }"
                    maxlength="50"
                    [readonly]="buttonArray.value.length == 8"
                  />
                  <button
                    nz-button
                    nzType="primary"
                    nzSize="large"
                    [class.disabled]="
                      buttonName.length == 0 ||
                      buttonName.length > 50 ||
                      buttonArray.value.length == 8
                    "
                    (click)="addNewButton()"
                  >
                    {{ "ADD" | translate }}
                    <span nz-icon nzType="right" nzTheme="outline"></span>
                  </button>
                </div>
              </ng-template>
            </nz-alert>
          </nz-form-item>

          <div>
            <!-- buttonArray -->
            <div
              class="d-flex flex-column align-items-center justify-content-evenly flex-wrap"
            >
              <ng-container
                *ngFor="let button of buttonArray.value; let i = index"
              >
                <div class="mb-4">
                  <button
                    class="button-preview position-relative"
                    [ngClass]="buttonStyleControl"
                    nz-button
                    nzType="primary"
                    nzSize="large"
                    [style.backgroundColor]="
                      buttonConfig?.backgroundColor != 'default'
                        ? buttonConfig?.backgroundColor
                        : courseService.getStyle()['primaryColor']
                    "
                    [style.color]="
                      buttonConfig?.color != 'default'
                        ? buttonConfig?.color
                        : courseService.getStyle()['primaryTextColor']
                    "
                    [style.borderColor]="
                      buttonConfig?.borderColor != 'default'
                        ? buttonConfig?.borderColor
                        : courseService.getStyle()['borderColor']
                    "
                    class="mse-10 button-preview text-wrap"
                    style="display: block; text-align: center"
                  >
                    {{ button }}
                    <span
                      nz-icon
                      nzType="delete"
                      nzTheme="outline"
                      (click)="removeButton(i)"
                    ></span>
                  </button>
                </div>
              </ng-container>
            </div>
          </div>
        </div>

        <div *ngIf="_addConsentButtonContainer">
          <nz-form-item>
            <nz-form-label>
              <span class="color-red">*&nbsp; </span>
            </nz-form-label>
            <nz-alert
              nzType="warning"
              nzMessage="Instructions"
              [nzDescription]="descriptionTpl"
              nzShowIcon
            >
              <ng-template #descriptionTpl>
                <div>- {{ "MAXIMUM_NUMBER_OF_BUTTONS" | translate }} 2</div>
                <div>
                  - {{ "MAXIMUM_NUMBER_OF_CHARACTERS_BUTTONS" | translate }} 50
                </div>
                <div class="d-flex justify-content-between align-items-center">
                  <input
                    nz-input
                    placeholder="Enter a button name ..."
                    nzSize="default"
                    class="button-name-input button-for-consent-input"
                    [(ngModel)]="buttonName"
                    [ngModelOptions]="{ standalone: true }"
                    maxlength="50"
                    [readonly]="buttonArray.value.length == 2"
                  />
                  <div class="radio-group">
                    <label>
                      <input 
                        type="radio" 
                        name="options" 
                        [(ngModel)]="consentType" 
                        [value]="true"
                        [disabled]="buttonArray.value.length == 2" 
                        [ngModelOptions]="{ standalone: true }" 
                      />
                      {{ "USER_CONSENT" | translate }}<b>Yes</b>
                    </label>
                    <label>
                      <input 
                        type="radio" 
                        name="options" 
                        [(ngModel)]="consentType" 
                        [value]="false" 
                        [disabled]="buttonArray.value.length == 2" 
                        [ngModelOptions]="{ standalone: true }" 
                      />
                      {{ "USER_CONSENT" | translate }}<b>No</b>
                    </label>
                  </div>
                  <button
                    nz-button
                    nzType="primary"
                    nzSize="large"
                    [class.disabled]="
                      buttonName.length == 0 ||
                      buttonName.length > 50 ||
                      buttonArray.value.length == 2
                    "
                    (click)="addNewConsentButton()"
                  >
                    {{ "ADD" | translate }}
                    <span nz-icon nzType="right" nzTheme="outline"></span>
                  </button>
                </div>
              </ng-template>
            </nz-alert>
          </nz-form-item>

          <div>
            <!-- buttonArray -->
            <div
              class="d-flex flex-column align-items-center justify-content-evenly flex-wrap"
            >
              <ng-container
                *ngFor="let button of buttonArray.value; let i = index"
              >
                <div class="mb-4">
                  <button
                    class="button-preview position-relative"
                    [ngClass]="buttonStyleControl"
                    nz-button
                    nzType="primary"
                    nzSize="large"
                    [style.backgroundColor]="buttonBackgroundColor(button)"
                    [style.color]="
                      buttonConfig?.color != 'default'
                        ? buttonConfig?.color
                        : courseService.getStyle()['primaryTextColor']
                    "
                    [style.borderColor]="
                      buttonConfig?.borderColor != 'default'
                        ? buttonConfig?.borderColor
                        : courseService.getStyle()['borderColor']
                    "
                    class="mse-10 button-preview text-wrap"
                    style="display: block; text-align: center"
                  >
                    {{ buttonDetails(button) }}
                    <span
                      nz-icon
                      nzType="delete"
                      nzTheme="outline"
                      (click)="removeButton(i)"
                    ></span>
                  </button>
                </div>
              </ng-container>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
