<div class="cent">
  <div>
    <app-hotspot-img [hotspots]="hotspots" [editable]="true" (hotspotsChange)="handleHotspotsChange($event)"
      [(selectedHotspot)]="selectedHotspot">
      <img [src]="imageUrl" alt="Image" />
    </app-hotspot-img>
  </div>
  <div>
    <nz-alert nzType="warning" nzMessage="Instructions" [nzDescription]="descriptionTplA" nzShowIcon>
      <ng-template #descriptionTplA>
        <div>
          - {{ "HOTSPOTS_INSTRUCTION_ONE" | translate }}
        </div>
        <div>
          - {{ "HOTSPOTS_INSTRUCTION_TWO" | translate }}
        </div>
        <div>
          - {{ "HOTSPOTS_INSTRUCTION_THREE" | translate }}
        </div>
        <div>
          - {{ "HOTSPOTS_INSTRUCTION_FOUR" | translate }}
        </div>
      </ng-template>
    </nz-alert>
  </div>
  
</div>

<ul class="list">
  <li
    *ngFor="let hotspot of hotspots; let i = index"
    [ngClass]="{ selected: hotspot === selectedHotspot }"
    (click)="selectedHotspot = hotspot"
  >
    <div>{{ i + 1 }}</div>
    <input type="text" [(ngModel)]="hotspot.data" />
    <button (click)="deleteHotspot(hotspot, i)">Delete</button>
  </li>
</ul>
