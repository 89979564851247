// src/app/services/auth.service.ts

import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { shareReplay, tap } from "rxjs/operators";
import { environment } from "../../environments/environment";
import { AuthRequest, AuthResponse } from "../models/auth-response.interface";
import { LocalStorageUtils } from "../core/utils";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  private apiUrl = environment.apiUrl;
  private authResponse$: Observable<AuthResponse> | undefined;

  constructor(private http: HttpClient) {}

  validateSecretKey(data: AuthRequest): Observable<AuthResponse> {
    // Delete existing data from localStorage
    LocalStorageUtils.deleteFromLocalStorage("authData");

    const url = `${this.apiUrl}handle-user/get-access-validateSecretKey`;
    this.authResponse$ = this.http.post<AuthResponse>(url, data).pipe(
      tap((response) => {
        // Save to localStorage
        LocalStorageUtils.saveToLocalStorage(
          "authData",
          response,
        );
      }),
      shareReplay(1), // Cache the last emitted value and share it with multiple subscribers
    );

    return this.authResponse$;
  }
}
