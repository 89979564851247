import { Component, OnInit } from "@angular/core";
import { LocalStorageUtils, Utils } from "../core/utils";
import { ActivatedRoute, Router } from "@angular/router";
import { ChangeDetectorRef } from "@angular/core";
import { NzMessageService } from "ng-zorro-antd/message";
import { CourseService } from "../services/course.service";
import { COURSE_CONST } from "../create-course/data";
import { AuthService } from "../services/auth.service";
import { CourseApiService } from "../services/course.api.service";
import { CourseListResponse, CourseRequest } from "../models/course-interface";
//import { Router } from '@angular/router';
import { TranslationService } from "src/app/services/translation.service";
import {
  NzTableFilterFn,
  NzTableFilterList,
  NzTableSortFn,
  NzTableSortOrder,
} from "ng-zorro-antd/table";
import { NzModalService } from "ng-zorro-antd/modal";
import { SpinnerService } from "../services/spinner.service";
import { environment } from "src/environments/environment";
import { QUIZ_CONST } from "../create-quiz/data";

@Component({
  selector: "app-view-courses",
  templateUrl: "./view-courses.component.html",
  styleUrls: ["./view-courses.component.scss"],
})
export class ViewCoursesComponent implements OnInit {
  courses: any[] = [];

  meta: any = {
    pagination: {
      page: 1,
      pageSize: 25,
      pageCount: 1,
      total: 25,
    },
  };

  listOfColumns: any[] = [];
  tableHeaders: string[] = [
    "Id",
    "CourseVersionIdentifier",
    "Title",
    "Status",
    "Version",
    "# Pages",
    "Created User",
    "Created At",
    "Updated At",
  ];

  column: any = {
    name: "",
    sortOrder: null,
    sortFn: null,
    listOfFilter: [],
    filterFn: null,
  };

  pageOptions: any = [10, 25, 50, 100];
  coursesResponse$: any;
  category: string = COURSE_CONST.COURSE_CATEGORY;
  isVisible = false;
  editPageNumber: number = 0;
  editPageSize: number = 0;
  editEventId: number = 0;

  production = false;

  constructor(
    private router: Router,
    private cdr: ChangeDetectorRef,
    private message: NzMessageService,
    private courseService: CourseService,
    private courseApiService: CourseApiService,
    private authService: AuthService,
    private spinner: SpinnerService,
    private modalService: NzModalService,
    private activateRoute: ActivatedRoute,
    private translationService: TranslationService,
  ) {}

  ngOnInit(): void {
    this.production = environment.production;
    Utils.checkAuthorization(this.router);
    this.setQueryParams();
    this.initializeData();
    this.courseService.applyStyles();
    this.mapColumnData();
  }

  private initializeData(): void {
    this.courseService.deleteCourseBuilderSteps();
    this.getCourses();
  }

  setQueryParams() {
    this.activateRoute.queryParams.subscribe((params) => {
      // Read and assign query parameters
      this.category = params["category"] || COURSE_CONST.COURSE_CATEGORY;
      if (this.category == QUIZ_CONST.QUIZ_CATEGORY) this.tableHeaders.splice(3, 0, "File Name");
    });
  }

  mapColumnData(): void {
    this.listOfColumns = this.tableHeaders.map((header: string) => ({
      name: header,
      sortOrder: null,
      sortFn: null,
      listOfFilter: [],
      filterFn: null,
    }));
  }

  getDisplayText(category: string): string {
    if (category === 'quiz') {
      return "QQSPs";
    }
    return "EEBs";
  }

  editCourse(data: any) {
    this.isVisible = true;
    this.editPageNumber = 0;
    this.editPageSize = data.totalSlideCount;
    this.editEventId = data.id;
  }

  onInputFocus(event: FocusEvent): void {
    // Use target to access the element that triggered the event
    (event.target as HTMLInputElement).select();
  }

  editCourseFromModal() {
    this.isVisible = false;

    if (this.editPageNumber == 0) {
      this.router.navigate([`/${this.category}`], {
        queryParams: { courseId: this.editEventId },
      });
    } else if (
      this.editPageNumber > 0 &&
      this.editPageNumber <= this.editPageSize
    ) {
      this.router.navigate([`/edit-${this.category}/${this.editEventId}`], {
        queryParams: {
          step: 2,
          slide: this.editPageNumber,
        },
      });
    }
  }
  parseInputValue(event: Event): number {
    const inputValue = (event.target as HTMLInputElement)?.valueAsNumber;
    return isNaN(inputValue) ? 0 : inputValue;
  }

  handleCancel() {
    this.isVisible = false;
  }

  createNextVersion(data: any) {
    this.courseApiService.createNextVersion(data.id).subscribe(
      (response: any) => {
        this.getCourses();
      },
      (error: any) => {},
    );
  }

  duplicateToLive(data: any) {
    this.modalService.create({
      nzTitle: this.translationService.translate("Confirmation"),
      nzContent: this.translationService.translate("ALERT_DUPLICATE_TO_LIVE"),
      nzOkText: this.translationService.translate("YES"),
      nzCancelText: this.translationService.translate("CANCEL"),
      nzMaskClosable: false,
      nzClosable: false,
      nzOnOk: () => {
        this.spinner.show();
        this.courseApiService.createCopy(data.id, environment.PENSIVO_ENVs.LIVE).subscribe(
          (response: any) => {
            this.modalService.create({
              nzTitle: "Information",
              nzContent: "This event has been successfully duplicated to LIVE",
              nzOkText: "OK",
              nzClosable: false,
              nzCancelText: null, // or nzCancelText: ''
              nzWidth: "40%",
              nzOnOk: () => {
                this.spinner.hide()
              },
            });
          },
          (error: any) => {
            this.spinner.hide()
          },
        );
      },
      nzOnCancel: () => {
        // User clicked Cancel
        // Do nothing or handle accordingly
      },
    });
  }

  createCopy(data: any) {
    this.spinner.show();
    this.courseApiService.createCopy(data.id, environment.PENSIVO_ENVs.CURRENT).subscribe(
      (response: any) => {
        this.modalService.closeAll();
          this.modalService.create({
          nzTitle: "Information",
          nzContent: "This event has been copied successfully ",
          nzOkText: "OK",
          nzClosable: false,
          nzCancelText: null, // or nzCancelText: ''
          nzWidth: "40%",
          nzOnOk: () => {
            this.getCourses();
          },
        });
      },
      (error: any) => { 
        this.spinner.hide();
      },
    );
  }

  deleteCourse(id: any) {
    this.modalService.create({
      nzTitle: this.translationService.translate("Confirmation"),
      nzContent: this.translationService.translate("ALERT_DELETE_COURSE"),
      nzOkText: this.translationService.translate("YES"),
      nzCancelText: this.translationService.translate("CANCEL"),
      nzMaskClosable: false,
      nzClosable: false,
      nzOnOk: () => {
        // User clicked OK
        this.courseApiService.deleteCourse(id).subscribe(
          (response: any) => {
            this.getCourses();
          },
          (error: any) => {},
        );
      },
      nzOnCancel: () => {
        // User clicked Cancel
        // Do nothing or handle accordingly
      },
    });
  }

  checkCompletedStatus(data: any) {
    return data.status != COURSE_CONST.COMPLETED;
  }

  checkForDuplicte(data: any) {
    return data.duplicated !== true && data.status == COURSE_CONST.COMPLETED;
  }

  searchData() {}

  handlePageIndexChange(pageIndex: number): void {
    this.getCourses(pageIndex, this.meta.pagination.pageSize); // Adding 1 because pagination usually starts from 1, not 0
  }

  handlePageSizeChange(pageSize: number): void {
    this.getCourses(1, pageSize);
  }

  getCourses(pageIndex: number = 1, pageSize: number = 25): void {
    this.spinner.show();
    // Update your API call with the new pageIndex and pageSize
    this.coursesResponse$ = this.courseApiService.getCourses(
      pageIndex,
      pageSize,
      this.category,
    );

    this.coursesResponse$.subscribe(
      (response: any) => {
        this.courses = response.data.map((item: any) => {
          const attributes = item.attributes;
          return { id: item.id, ...attributes };
        });
        this.meta = response.meta;
        this.modalService.closeAll();
        this.cdr.detectChanges();
      },
      (error: any) => {
        this.modalService.closeAll();
      },
    );
  }

  getColumnValue(data: any, column: string): string {
    // Convert column name to camelCase if needed (e.g., 'Created User' to 'createdUser')
    const columnName = column.replace(/\s+/g, "").toLowerCase();

    // Handle the case where column is not a string
    if (typeof column !== "string") {
      console.error(`Invalid column type: ${typeof column}`, column);
      return ""; // or handle it in a way that makes sense for your application
    }

    // Handle specific columns or nested properties
    switch (columnName) {
      case "#pages":
        return data.totalSlideCount;
      case "courseversionidentifier":
        return data.courseVersionIdentifier;
      case "createduser":
        return `${data.user.data.attributes.firstName} ${data.user.data.attributes.lastName}`;
      case "createdat":
        return this.formatDate(data.createdAt);
      case "updatedat":
        return this.formatDate(data.updatedAt);
      case "filename":
        return data.globalConfigs?.fileName;
      default:
        // For other columns, return the value directly
        return data[columnName];
    }
  }

  formatDate(date: string): string {
    const options = {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      timeZone: "America/New_York", // Eastern Time
      timeZoneName: "short", // Display timezone abbreviation (e.g., EDT)
    } as Intl.DateTimeFormatOptions;

    const formattedDate = new Intl.DateTimeFormat("en-US", options).format(
      new Date(date),
    );
    return formattedDate;
  }
}
