import { Directive, ElementRef, Input, Renderer2, AfterViewInit } from '@angular/core';

@Directive({
    selector: '[imageLazyLoad]'
})
export class LazyLoadDirective implements AfterViewInit {
    @Input() imageLazyLoad: string = '';

    constructor(private el: ElementRef, private renderer: Renderer2) { }

    ngAfterViewInit() {
        const observer = new IntersectionObserver(([entry]) => {
            if (entry.isIntersecting) {
                this.renderer.setAttribute(this.el.nativeElement, 'src', this.imageLazyLoad);
                observer.unobserve(this.el.nativeElement);
            }
        });

        observer.observe(this.el.nativeElement);
    }
}