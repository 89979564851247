import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { TranslationPipe } from "./pipes/translation.pipe";
import { LazyLoadDirective } from "./directives/lazy-loading-image.directive";

@NgModule({
  declarations: [TranslationPipe, LazyLoadDirective],
  imports: [CommonModule],
  exports: [TranslationPipe, LazyLoadDirective],
})
export class SharedModule {}
