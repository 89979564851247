<div class="headings">
  <h4 nz-typography>
    {{ "SLIDE_TYPE_TEXT" | translate }}
  </h4>
</div>
<form
  nz-form
  *ngIf="validateForm"
  [nzLayout]="'vertical'"
  [formGroup]="validateForm"
  (ngSubmit)="submitForm()"
>
  <!--  -->
  <nz-form-item>
    <nz-form-label nzRequired nzFor="slideTypeOptions">{{
      "SLIDE_TYPE" | translate
    }}</nz-form-label>
    <nz-form-control nzErrorTip="{{ 'SELECT_LAYER_PLACEHOLDER' | translate }}">
      <nz-select
        id="slideTypeOptions"
        formControlName="layer"
        nzPlaceHolder="{{ 'SELECT_PLACEHOLDER' | translate }}"
        (ngModelChange)="onLayerSelect($event)"
      >
        <nz-option
          *ngFor="let option of slideTypeOptions"
          [nzValue]="option.value"
          [nzLabel]="option.label"
        ></nz-option>
      </nz-select>
    </nz-form-control>
  </nz-form-item>
  <!--  -->
  <nz-form-item>
    <nz-form-label nzRequired nzFor="layerOptions">{{
      "LAYER_OPTIONS" | translate
    }}</nz-form-label>
    <nz-form-control
      nzErrorTip="{{ 'SELECT_LAYER_OPTION_PLACEHOLDER' | translate }}"
    >
      <nz-select
        id="layerOptions"
        formControlName="layerOptions"
        nzPlaceHolder="{{ 'SELECT_PLACEHOLDER' | translate }}"
        (ngModelChange)="onLayerOptionSelect($event)"
      >
        <nz-option
          *ngFor="let option of currentLayerOptions"
          [nzValue]="option.value"
          [nzLabel]="option.label"
        ></nz-option>
      </nz-select>
    </nz-form-control>
  </nz-form-item>
  <!--  -->
  <nz-form-item *ngIf="!showMCQRelatedConfiguration">
    <nz-form-label nzRequired nzFor="enabledNext"
      >{{ "SLIDE_COMPLETION_RULE" | translate }}
    </nz-form-label>
    <nz-form-control nzErrorTip="{{ 'SELECT_LAYER_PLACEHOLDER' | translate }}">
      <nz-select
        id="enabledNext"
        formControlName="enabledNext"
        nzPlaceHolder="{{ 'SELECT_PLACEHOLDER' | translate }}"
      >
        <nz-option
          *ngFor="let option of completionRuleOptions"
          [nzValue]="option.value"
          [nzLabel]="option.label"
        ></nz-option>
      </nz-select>
    </nz-form-control>
  </nz-form-item>
  <nz-form-item *ngIf="!showMCQRelatedConfiguration">
    <nz-form-label nzRequired nzFor="pageLayoutOption">
      {{ "SLIDE_PAGE_LAYOUT" | translate }}
    </nz-form-label>
    <nz-form-control
      nzErrorTip="{{ 'SELECT_PAGE_LAYOUT_OPTION_PLACEHOLDER' | translate }}"
    >
      <nz-select
        id="pageLayoutOption"
        formControlName="pageLayoutOption"
        nzPlaceHolder="{{ 'SELECT_PLACEHOLDER' | translate }}"
      >
        <nz-option
          *ngFor="let option of pageLayoutOptions"
          [nzValue]="option.value"
          [nzLabel]="option.label"
        ></nz-option>
      </nz-select>
    </nz-form-control>
  </nz-form-item>
  <!--  -->
  <nz-form-item *ngIf="showVideoPlayingSelector">
    <nz-form-label nzRequired nzFor="videoOption"
      >{{ "SLIDE_VIDEO_PLAYER_OPTION" | translate }}
    </nz-form-label>
    <nz-form-control
      nzErrorTip="{{ 'SELECT_VIDEO_PLAYER_OPTION_PLACEHOLDER' | translate }}"
    >
      <nz-select
        id="videoOption"
        formControlName="videoOption"
        nzPlaceHolder="{{ 'SELECT_PLACEHOLDER' | translate }}"
      >
        <nz-option
          *ngFor="let option of videoPlayerOptions"
          [nzValue]="option.value"
          [nzLabel]="option.label"
        ></nz-option>
      </nz-select>
    </nz-form-control>
  </nz-form-item>
  <!--  -->
  <nz-form-item *ngIf="showButtonConfiguration">
    <nz-form-label nzRequired nzFor="buttonLayout"
      >{{ "SLIDE_BUTTON_LAYOUT" | translate }}
    </nz-form-label>
    <nz-form-control
      nzErrorTip="{{ 'SELECT_VIDEO_PLAYER_OPTION_PLACEHOLDER' | translate }}"
    >
      <nz-select
        id="buttonLayout"
        formControlName="buttonLayout"
        nzPlaceHolder="{{ 'SELECT_PLACEHOLDER' | translate }}"
      >
        <nz-option
          *ngFor="let option of buttonLayoutOptions"
          [nzValue]="option.value"
          [nzLabel]="option.label"
        ></nz-option>
      </nz-select>
    </nz-form-control>
  </nz-form-item>
  <!--  -->
  <nz-form-item *ngIf="showButtonConfiguration">
    <nz-form-label nzRequired nzFor="buttonAppearance"
      >{{ "SLIDE_BUTTON_APPEARANCE" | translate }}
    </nz-form-label>
    <nz-form-control
      nzErrorTip="{{ 'SELECT_VIDEO_PLAYER_OPTION_PLACEHOLDER' | translate }}"
    >
      <nz-select
        id="buttonAppearance"
        formControlName="buttonAppearance"
        nzPlaceHolder="{{ 'SELECT_PLACEHOLDER' | translate }}"
      >
        <nz-option
          *ngFor="let option of buttonAppearancOption"
          [nzValue]="option.value"
          [nzLabel]="option.label"
        ></nz-option>
      </nz-select>
    </nz-form-control>
  </nz-form-item>
  <!--  -->
  <div
    class="d-flex justify-content-between align-items-center flex-wrap"
    *ngIf="showButtonConfiguration || showConsentButtonConfiguration"
  >
    <div class="d-flex justify-content-start align-items-center w-100">
      <div class="w-19" *ngIf="showConsentButtonConfiguration">
        <nz-form-item>
          <nz-form-label nzRequired nzFor="consentTrueButtonFillColor" innerHTML="{{ 'CONSENT_BUTTON_TRUE_FILL_COLOR' | translate }}">
          </nz-form-label>
          <nz-form-control
            nzErrorTip="{{ 'SELECT_COLOR_OPTION_PLACEHOLDER' | translate }}"
          >
            <nz-select
              id="consentTrueButtonFillColor"
              formControlName="consentTrueButtonFillColor"
              nzPlaceHolder="{{ 'SELECT_PLACEHOLDER' | translate }}"
              [nzCustomTemplate]="selectedTemplateButtonFill">
          
              <!-- Template pour l'option sélectionnée -->
              <ng-template #selectedTemplateButtonFill let-selectedButtonFill>
                <div class="option" style="align-items: center; gap: 8px;">
                  <div
                    class="color-option"
                    *ngIf="selectedButtonFill.nzValue === 'default'"
                    [style.backgroundColor]="courseService.getStyle()['primaryColor']"
                    style="width: 16px; height: 16px; border-radius: 4px;"
                  ></div>
                  <div
                    class="color-option"
                    *ngIf="selectedButtonFill.nzValue !== 'default'"
                    [class.bordered]="selectedButtonFill.nzValue === 'white'"
                    [style.backgroundColor]="selectedButtonFill.nzValue"
                    style="width: 16px; height: 16px; border-radius: 4px;"
                  ></div>
                  <span style="margin: 0; font-size: 14px;">{{ selectedButtonFill.nzLabel }}</span>
                </div>
              </ng-template>
             
            <ng-container *ngFor="let option of buttonConfigOptions">
              <nz-option 
                nzCustomContent
                [nzValue]="option.value"
                [nzLabel]="option.label"
              >
              <div class="option">
                <div 
                  class="color-option" 
                  *ngIf="option.value === 'default'" 
                  [class.bordered]="courseService.getStyle()['primaryTextColor'] === 'white'" 
                  [style.backgroundColor]="courseService.getStyle()['primaryColor']"></div>
                <div class="color-option" *ngIf="option.value != 'default'" [class.bordered]="option.value === 'white'" [style.backgroundColor]="option.label"></div>
                <span style="margin-top: 0; margin-bottom: 4px;"> {{option.label}}</span>
              </div>
              </nz-option>
            </ng-container>
            </nz-select>
          </nz-form-control>
        </nz-form-item>
      </div>
      <div class="w-19" *ngIf="showConsentButtonConfiguration" style="margin-left: 7.5vw;">
        <nz-form-item>
          <nz-form-label nzRequired nzFor="consentFalseButtonFillColor" innerHTML="{{ 'CONSENT_BUTTON_FALSE_FILL_COLOR' | translate }}">
          </nz-form-label>
          <nz-form-control
            nzErrorTip="{{ 'SELECT_COLOR_OPTION_PLACEHOLDER' | translate }}"
          >
            <nz-select
              id="consentFalseButtonFillColor"
              formControlName="consentFalseButtonFillColor"
              nzPlaceHolder="{{ 'SELECT_PLACEHOLDER' | translate }}"
              [nzCustomTemplate]="selectedTemplateButtonFill">
          
              <!-- Template pour l'option sélectionnée -->
              <ng-template #selectedTemplateButtonFill let-selectedButtonFill>
                <div class="option" style="align-items: center; gap: 8px;">
                  <div
                    class="color-option"
                    *ngIf="selectedButtonFill.nzValue === 'default'"
                    [style.backgroundColor]="courseService.getStyle()['primaryColor']"
                    style="width: 16px; height: 16px; border-radius: 4px;"
                  ></div>
                  <div
                    class="color-option"
                    *ngIf="selectedButtonFill.nzValue !== 'default'"
                    [class.bordered]="selectedButtonFill.nzValue === 'white'"
                    [style.backgroundColor]="selectedButtonFill.nzValue"
                    style="width: 16px; height: 16px; border-radius: 4px;"
                  ></div>
                  <span style="margin: 0; font-size: 14px;">{{ selectedButtonFill.nzLabel }}</span>
                </div>
              </ng-template>
             
            <ng-container *ngFor="let option of buttonConfigOptions">
              <nz-option 
                nzCustomContent
                [nzValue]="option.value"
                [nzLabel]="option.label"
              >
              <div class="option">
                <div 
                  class="color-option" 
                  *ngIf="option.value === 'default'" 
                  [class.bordered]="courseService.getStyle()['primaryTextColor'] === 'white'" 
                  [style.backgroundColor]="courseService.getStyle()['primaryColor']"></div>
                <div class="color-option" *ngIf="option.value != 'default'" [class.bordered]="option.value === 'white'" [style.backgroundColor]="option.label"></div>
                <span style="margin-top: 0; margin-bottom: 4px;"> {{option.label}}</span>
              </div>
              </nz-option>
            </ng-container>
            </nz-select>
          </nz-form-control>
        </nz-form-item>
      </div>
    </div>
    <div class="w-19" *ngIf="showButtonConfiguration">
      <nz-form-item>
        <nz-form-label nzRequired nzFor="buttonFillColor"
          >{{ "SLIDE_BUTTON_FILL_COLOR" | translate }}
        </nz-form-label>
        <nz-form-control
          nzErrorTip="{{ 'SELECT_COLOR_OPTION_PLACEHOLDER' | translate }}"
        >
          <nz-select
            id="buttonFillColor"
            formControlName="buttonFillColor"
            nzPlaceHolder="{{ 'SELECT_PLACEHOLDER' | translate }}"
            [nzCustomTemplate]="selectedTemplateButtonFill">
        
            <!-- Template pour l'option sélectionnée -->
            <ng-template #selectedTemplateButtonFill let-selectedButtonFill>
              <div class="option" style="align-items: center; gap: 8px;">
                <div
                  class="color-option"
                  *ngIf="selectedButtonFill.nzValue === 'default'"
                  [style.backgroundColor]="courseService.getStyle()['primaryColor']"
                  style="width: 16px; height: 16px; border-radius: 4px;"
                ></div>
                <div
                  class="color-option"
                  *ngIf="selectedButtonFill.nzValue !== 'default'"
                  [class.bordered]="selectedButtonFill.nzValue === 'white'"
                  [style.backgroundColor]="selectedButtonFill.nzValue"
                  style="width: 16px; height: 16px; border-radius: 4px;"
                ></div>
                <span style="margin: 0; font-size: 14px;">{{ selectedButtonFill.nzLabel }}</span>
              </div>
            </ng-template>
           
          <ng-container *ngFor="let option of buttonConfigOptions">
            <nz-option 
              nzCustomContent
              [nzValue]="option.value"
              [nzLabel]="option.label"
            >
            <div class="option">
              <div 
                class="color-option" 
                *ngIf="option.value === 'default'" 
                [class.bordered]="courseService.getStyle()['primaryTextColor'] === 'white'" 
                [style.backgroundColor]="courseService.getStyle()['primaryColor']"></div>
              <div class="color-option" *ngIf="option.value != 'default'" [class.bordered]="option.value === 'white'" [style.backgroundColor]="option.label"></div>
              <span style="margin-top: 0; margin-bottom: 4px;"> {{option.label}}</span>
            </div>
            </nz-option>
          </ng-container>
          </nz-select>
        </nz-form-control>
      </nz-form-item>
    </div>
    <div class="w-19">
      <nz-form-item>
        <nz-form-label nzRequired nzFor="buttonBorderColor"
          >{{ "SLIDE_BUTTON_BORDER_COLOR" | translate }}
        </nz-form-label>
        <nz-form-control
          nzErrorTip="{{ 'SELECT_COLOR_OPTION_PLACEHOLDER' | translate }}"
        >
          <nz-select
            id="buttonBorderColor"
            formControlName="buttonBorderColor"
            nzPlaceHolder="{{ 'SELECT_PLACEHOLDER' | translate }}"
            [nzCustomTemplate]="selectedTemplateBorder">
            <ng-container *ngFor="let option of buttonConfigOptions">
              <nz-option 
                nzCustomContent
                [nzValue]="option.value"
                [nzLabel]="option.label"
              >
              <div class="option">
                <div 
                  class="color-option" 
                  *ngIf="option.value === 'default'"
                  [class.bordered]="courseService.getStyle()['borderColor'].split(' ')[1] === 'white'"
                  [style.backgroundColor]="courseService.getStyle()['borderColor'].split(' ')[1]"></div>
                <div class="color-option" *ngIf="option.value != 'default'" [class.bordered]="option.value === 'white'" [style.backgroundColor]="option.label"></div>
                <span style="margin-top: 0; margin-bottom: 4px;"> {{option.label}}</span>
              </div>
              </nz-option>
            </ng-container>
            <!-- Template pour l'option sélectionnée -->
            <ng-template #selectedTemplateBorder let-selectedBorder>
              <div class="option" style="align-items: center; gap: 8px;">
                <div
                  class="color-option"
                  *ngIf="selectedBorder.nzValue === 'default'"
                  [class.bordered]="courseService.getStyle()['primaryTextColor'] === 'white'"
                  [style.backgroundColor]="courseService.getStyle()['borderColor'].split(' ')[1]"
                  style="width: 16px; height: 16px; border-radius: 4px;"
                ></div>
                <div
                  class="color-option"
                  *ngIf="selectedBorder.nzValue !== 'default'"
                  [class.bordered]="selectedBorder.nzValue === 'white'"
                  [style.backgroundColor]="selectedBorder.nzValue"
                  style="width: 16px; height: 16px; border-radius: 4px;"
                ></div>
                <span style="margin: 0; font-size: 14px;">{{ selectedBorder.nzLabel }}</span>
              </div>
            </ng-template>
          </nz-select>
        </nz-form-control>
      </nz-form-item>
    </div>
    <div class="w-19">
      <nz-form-item>
        <nz-form-label nzRequired nzFor="buttonTextColor"
          >{{ "SLIDE_BUTTON_TEXT_COLOR" | translate }}
        </nz-form-label>
        <nz-form-control
          nzErrorTip="{{ 'SELECT_COLOR_OPTION_PLACEHOLDER' | translate }}"
        >
          <nz-select
            id="buttonTextColor"
            formControlName="buttonTextColor"
            nzPlaceHolder="{{ 'SELECT_PLACEHOLDER' | translate }}"
            [nzCustomTemplate]="selectedTemplateButtonText">
        
            <!-- Template pour l'option sélectionnée -->
            <ng-template #selectedTemplateButtonText let-selected>
              <div class="option" style="align-items: center; gap: 8px;">
                <div
                  class="color-option"
                  *ngIf="selected.nzValue === 'default'"
                  [class.bordered]="courseService.getStyle()['primaryTextColor'] === 'white'"
                  [style.backgroundColor]="courseService.getStyle()['primaryTextColor']"
                  style="width: 16px; height: 16px; border-radius: 4px;"
                ></div>
                <div
                  class="color-option"
                  *ngIf="selected.nzValue !== 'default'"
                  [class.bordered]="selected.nzValue === 'white'"
                  [style.backgroundColor]="selected.nzValue"
                  style="width: 16px; height: 16px; border-radius: 4px;"
                ></div>
                <span style="margin: 0; font-size: 14px;">{{ selected.nzLabel }}</span>
              </div>
            </ng-template>
          <ng-container *ngFor="let option of buttonConfigOptions">
            <nz-option 
              nzCustomContent
              [nzValue]="option.value"
              [nzLabel]="option.label"
            >
            <div class="option">
              <div 
                class="color-option" 
                *ngIf="option.value === 'default'" 
                [class.bordered]="courseService.getStyle()['primaryTextColor'] === 'white'" 
                [style.backgroundColor]="courseService.getStyle()['primaryTextColor']"></div>
              <div class="color-option" *ngIf="option.value != 'default'" [class.bordered]="option.value === 'white'" [style.backgroundColor]="option.label"></div>
              <span style="margin-top: 0; margin-bottom: 4px;"> {{option.label}}</span>
            </div>
            </nz-option>
          </ng-container>
          </nz-select>
        </nz-form-control>
      </nz-form-item>
    </div>
    <div class="w-19">
      <nz-form-item>
        <nz-form-label nzRequired nzFor="buttonActiveColor"
          >{{ "SLIDE_BUTTON_ACTIVE_COLOR" | translate }}
        </nz-form-label>
        <nz-form-control
          nzErrorTip="{{ 'SELECT_COLOR_OPTION_PLACEHOLDER' | translate }}"
        >
          <nz-select
            id="buttonActiveColor"
            formControlName="buttonActiveColor"
            nzPlaceHolder="{{ 'SELECT_PLACEHOLDER' | translate }}"
            [nzCustomTemplate]="selectedTemplateButtonActive">
        
            <!-- Template pour l'option sélectionnée -->
            <ng-template #selectedTemplateButtonActive let-selected>
              <div class="option" style="align-items: center; gap: 8px;">
                <div
                  class="color-option"
                  *ngIf="selected.nzValue === 'default'"
                  [class.bordered]="courseService.getStyle()['primaryColor'] === 'white'" 
                  [style.backgroundColor]="courseService.getStyle()['primaryColor']"
                  style="width: 16px; height: 16px; border-radius: 4px;"
                ></div>
                <div
                  class="color-option"
                  *ngIf="selected.nzValue !== 'default'"
                  [class.bordered]="selected.nzValue === 'white'"
                  [style.backgroundColor]="selected.nzValue"
                  style="width: 16px; height: 16px; border-radius: 4px;"
                ></div>
                <span style="margin: 0; font-size: 14px;">{{ selected.nzLabel }}</span>
              </div>
            </ng-template>
          <ng-container *ngFor="let option of buttonConfigOptions">
            <nz-option 
              nzCustomContent
              [nzValue]="option.value"
              [nzLabel]="option.label"
            >
            <div class="option">
              <div 
                class="color-option" 
                *ngIf="option.value === 'default'" 
                [class.bordered]="courseService.getStyle()['primaryColor'] === 'white'" 
                [style.backgroundColor]="courseService.getStyle()['primaryColor']"></div>
              <div class="color-option" *ngIf="option.value != 'default'" [class.bordered]="option.value === 'white'" [style.backgroundColor]="option.label"></div>
              <span style="margin-top: 0; margin-bottom: 4px;"> {{option.label}}</span>
            </div>
            </nz-option>
          </ng-container>
          </nz-select>
        </nz-form-control>
      </nz-form-item>
    </div>
    <div class="w-19">
      <nz-form-item>
        <nz-form-label nzRequired nzFor="buttonActiveTextColor"
          >{{ "SLIDE_BUTTON_ACTIVE_TEXT_COLOR" | translate }}
        </nz-form-label>
        <nz-form-control
          nzErrorTip="{{ 'SELECT_COLOR_OPTION_PLACEHOLDER' | translate }}"
        >
        <nz-select
        id="buttonActiveTextColor"
        formControlName="buttonActiveTextColor"
        nzPlaceHolder="{{ 'SELECT_PLACEHOLDER' | translate }}"
        [nzCustomTemplate]="selectedTemplate">
        
        <!-- Template pour l'option sélectionnée -->
        <ng-template #selectedTemplate let-selected>
          <div class="option" style="align-items: center; gap: 8px;">
            <div
              class="color-option"
              *ngIf="selected.nzValue === 'default'"
              [class.bordered]="courseService.getStyle()['primaryTextColor'] === 'white'" 
              [style.backgroundColor]="courseService.getStyle()['primaryTextColor']"
              style="width: 16px; height: 16px; border-radius: 4px;"
            ></div>
            <div
              class="color-option"
              *ngIf="selected.nzValue !== 'default'"
              [class.bordered]="selected.nzValue === 'white'"
              [style.backgroundColor]="selected.nzValue"
              style="width: 16px; height: 16px; border-radius: 4px;"
            ></div>
            <span style="margin: 0; font-size: 14px;">{{ selected.nzLabel }}</span>
          </div>
        </ng-template>
        
      
        <!-- Liste des options -->
        <ng-container *ngFor="let option of buttonActiveTextColorOptions">
          <nz-option 
            nzCustomContent
            [nzValue]="option.value"
            [nzLabel]="option.label">
            <div class="option">
              <div class="color-option" 
                   *ngIf="option.value === 'default'"
                   [class.bordered]="courseService.getStyle()['primaryTextColor'] === 'white'" 
                   [style.backgroundColor]="courseService.getStyle()['primaryTextColor']"></div>
              <div class="color-option" 
                   *ngIf="option.value !== 'default'" 
                   [class.bordered]="option.value === 'white'" 
                   [style.backgroundColor]="option.label"></div>
              <span style="margin-top: 0; margin-bottom: 4px;"> {{ option.label }}</span>
            </div>
          </nz-option>
        </ng-container>
      </nz-select>
        </nz-form-control>
      </nz-form-item>
    </div>
  </div>
  <!--  -->
  <nz-form-item *ngIf="showMCQRelatedConfiguration">
    <nz-form-label nzRequired nzFor="correctAnswerProceeding">
      {{ "IF_ANSWER_CORRECTLY_CONFIG" | translate }}
    </nz-form-label>
    <nz-form-control nzErrorTip="{{ 'SELECT_LAYER_PLACEHOLDER' | translate }}">
      <nz-select
        id="correctAnswerProceeding"
        formControlName="correctAnswerProceeding"
        nzPlaceHolder="{{ 'SELECT_PLACEHOLDER' | translate }}"
      >
        <nz-option
          *ngFor="let option of correctAnswerProceedingOptions"
          [nzValue]="option.value"
          [nzLabel]="option.label"
          [nzDisabled]="option.disabled"
        ></nz-option>
      </nz-select>
    </nz-form-control>
  </nz-form-item>
  <!--  -->
  <nz-form-item *ngIf="showMCQRelatedConfiguration">
    <nz-form-label nzRequired nzFor="incorrectAnswerProceeding">
      {{ "IF_ANSWER_INCORRECTLY_CONFIG" | translate }}
    </nz-form-label>
    <nz-form-control nzErrorTip="{{ 'SELECT_LAYER_PLACEHOLDER' | translate }}">
      <nz-select
        id="incorrectAnswerProceeding"
        formControlName="incorrectAnswerProceeding"
        nzPlaceHolder="{{ 'SELECT_PLACEHOLDER' | translate }}"
      >
        <nz-option
          *ngFor="let option of incorrectAnswerProceedingOptions"
          [nzValue]="option.value"
          [nzLabel]="option.label"
          [nzDisabled]="option.disabled"
        ></nz-option>
      </nz-select>
    </nz-form-control>
  </nz-form-item>
  <!--  -->
  <nz-form-item> </nz-form-item>
</form>