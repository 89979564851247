import { Component, Inject, Input, OnDestroy } from "@angular/core";
import { ImgHotspot } from "../img-hotspot";
import { NZ_MODAL_DATA, NzModalRef } from "ng-zorro-antd/modal";
import { TranslationService } from "src/app/services/translation.service";
import { CourseService } from "src/app/services/course.service";
import { SupportedAsset } from "src/app/models/enum/supported-asset.enum";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-manage-hotspot-img",
  templateUrl: "./manage-hotspot-img.component.html",
  styleUrls: ["./manage-hotspot-img.component.scss"],
})
export class ManageHotspotImgComponent {
  @Input() imageUrl: string = "";
  @Input() hotspots: ImgHotspot<string>[] = [];
  selectedHotspot?: ImgHotspot<string>;

  clickEvents: string[] = [];

  constructor(
    public translationService: TranslationService,
    @Inject(NZ_MODAL_DATA) public data: any,
    private modalRef: NzModalRef,
    public courseService: CourseService
  ) {
    this.hotspots = data.hotspots;
    this.imageUrl = data.imageUrl;
  }

  handleHotspotsChange($event: ImgHotspot[]) {
    if (this.hotspots?.length < environment.hotspotsCount) {
      this.hotspots = $event;
    }
  }

  deleteHotspot(hotspot: ImgHotspot, index: number) {
    this.hotspots = this.hotspots.filter((h) => h !== hotspot);

    this.courseService.removingAssetsFromStepTwo({
      assetName: SupportedAsset.HOTSPOT,
      assetIndex: index
    })
  }

  closeModal() {
    this.modalRef.destroy(this.hotspots);
  }
}
