<div
  class="video-player-parent"
  [ngClass]="{
    'height-adjusted-video': isAudioPlayerAvailable,
    'full-height-video': !isAudioPlayerAvailable,
    'hide': isLoading
  }"
>
  <video
    #target
    controls
    playsinline
    preload="metadata"
    class="video-js"
    [ngClass]="{
      'video-js-container': playerInitialized
    }"
  ></video>
</div>

<img *ngIf="isLoading" src="../../../../assets/images/loading.gif" class="resizeable-img-class" />