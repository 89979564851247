export const environment = {
  production: false,
  apiUrl: "https://pensivostrapi.perkansolutions.com/api/",
  logoUrl: "https://pensivostrapi.perkansolutions.com",
  PENSIVO_ENVs: {
    CURRENT: "DEV",
    LIVE: "QA",
  },
  redirectURL: {
    TBS: "perkansolutions.com/",
    BDL: "https://perkansolutions.com/",
    EDE: "https://perkansolutions.com/",
    EDE2: "https://perkansolutions.com/",
  } as Record<string, string>,
  imageConfig: {
    maxSize: 1000,
    minSize: 1,
    maxFileCount: 3,
    allowedImageTypes: ["image/png", "image/jpg", "image/jpeg", "image/gif"],
    allowedFileTypes: ".png, .jpg, .jpeg, .gif",
  },
  videoConfig: {
    maxSize: 2000,
    minSize: 100,
    maxFileCount: 1,
    allowedImageTypes: [
      "video/mp4",
      "video/mov",
      "video/m4v",
      "video/webm",
      "video/quicktime",
    ],
    allowedFileTypes: ".mp4, .mov, .m4v, .webm, .quicktime",
  },
  audioConfig: {
    maxSize: 15000,
    minSize: 10,
    maxFileCount: 1,
    allowedImageTypes: [
      "audio/mpeg",
      "audio/wav",
      "audio/ogg",
      "audio/mp3",
      "audio/x-m4a",
    ],
    allowedFileTypes: ".mp3, .wav, .ogg, .m4a",
    audioRecorderCountdown: 3,
  },
  clientCss: {
    TBS: {
      bgColorDelBtn: "red",
      borderColor: "solid grey 1px",
      cardBoxShadowColor: "solid black 1px",
      congratulationColor: "black",
      primaryColor: "#F4B233",
      secondaryColor: "black",
      logoColor: "black",
      thirdColor: "eee",
      secondaryTextColor: "black",
      PageTitleBottomColor: "transparent",
      primaryTextColor: "white",
      viewPrevNextButton: "grey",
      mainButtonTextColor: "black",
      viewBackground:
        "linear-gradient(180deg, rgba(113, 112, 115, 1) 0%, rgba(251, 176, 64, 1) 100%)",
    },
    BDL: {
      bgColorDelBtn: "red",
      borderColor: "solid grey 1px",
      cardBoxShadowColor: "solid black 1px",
      congratulationColor: "black",
      primaryColor: "#F4B233",
      secondaryColor: "black",
      logoColor: "black",
      thirdColor: "eee",
      secondaryTextColor: "black",
      PageTitleBottomColor: "transparent",
      primaryTextColor: "white",
      viewPrevNextButton: "grey",
      mainButtonTextColor: "black",
      viewBackground:
        "linear-gradient(180deg, rgba(113, 112, 115, 1) 0%, rgba(251, 176, 64, 1) 100%)",
    },
    EDE: {
      logoColor: "#e6e6e6",
      thirdColor: "#aeb7bd",
      borderColor: "solid grey 1px",
      primaryColor: "#831f43",
      bgColorDelBtn: "red",
      secondaryColor: "#3A9DA8",
      viewBackground:
        "/uploads/background_ede_e20ce5cec9.png?updatedAt=2023-12-30T04%3A32%3A27.273Z",
      primaryTextColor: "white",
      cardBoxShadowColor: "solid black 1px",
      secondaryTextColor: "black",
      viewPrevNextButton: "grey",
      congratulationColor: "black",
      PageTitleBottomColor: "transparent",
      mainButtonTextColor: "white",
    },
  } as Record<string, any>,
  hotspotsCount: 6,
};
