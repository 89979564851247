<div class="course-slide-content">
  <div class="row">
    <div class="col-md-12">
      <div class="card card-custom">

        <div class="card card-custom justify-content-center align-items-center" *ngIf="!showResult">
          <p class="congratulations-text" *ngIf="!quizAttempts?.length || !course?.globalConfigs?.correctIncorrectAnswerExist || quizAttempts?.length && !course?.globalConfigs?.isTherePassingGrade">
            {{'QUIZ_COMPLETED' | translate}}
          </p>
          <div *ngIf="quizAttempts?.length && course?.globalConfigs?.isTherePassingGrade && course.globalConfigs?.passingPercentage > 0">
            <h3>Results of This Attempt</h3>
            <br>
            <p>
              Grade Achieved: {{quizAttempts[quizAttempts?.length-1].Grade}} %
              <br>
              Passing Grade: {{this.course.globalConfigs?.passingPercentage}} %
              <br>
              Status: {{getStatus()}}
              <br>
            </p>
          </div>
          <br>
          <h3>Click OK to continue</h3>
          <br>
          <button (click)="viewResult()" title="View Result" nz-button nzType="primary" nzSize="large" 
                  *ngIf="course?.globalConfigs.displayAnswerCopy && quizAttempts?.length"
            class="button-container justify-content-center align-items-center">
            View My Answers
          </button>
        </div>

        <div class="result" *ngIf="showResult">
          <div class="quiz-container">
            <nz-tabset [(nzSelectedIndex)]="attemptIndex">
              <nz-tab
                *ngFor="let quizAttempt of quizAttempts; let idx = index"
                [nzTitle]="'Attempt ' + (idx + 1)"
              >
                <div class="quiz-result-container">
                  <div class="attempt-summary">
                    <div *ngIf="course?.globalConfigs?.isTherePassingGrade">
                      <b>Grade:</b>
                      <span *ngIf="course?.globalConfigs.correctIncorrectAnswerExist">
                        {{ quizAttempts[idx]?.Grade | number: '1.0-0' }}%
                      </span>
                      <span *ngIf="!course?.globalConfigs.correctIncorrectAnswerExist">
                        {{ quizAttempts[idx]?.ScoredPoints }}/
                        {{ quizAttempts[idx]?.TotalPoints }}
                      </span>
                      <span class="status">{{ quizAttempts[idx]?.Status }}</span>
                    </div>
                  </div>
            
                  <div *ngFor="let slide of quizAttempt.QuizResult; let i = index" class="question">
                    <!-- Multi-Choice Question -->
                    <div *ngIf="slide.questionType === 'multiChoice'" class="question-type">
                      <p>
                        {{ i + 1 }}. {{ slide.mainQuestion.question }}
                        <span *ngIf="slide?.answerStatus == 1" class="correct">(Correct)</span>
                        <span *ngIf="slide?.answerStatus == 2" class="incorrect">(Incorrect)</span>
                      </p>
                      <ul>
                        <li
                          *ngFor="let option of slide.multiChoice"
                          [ngClass]="{
                            'correct': option.correct_answer,
                            'incorrect': !option.correct_answer && option.user_answer
                          }"
                        >
                          <i
                            [ngClass]="{
                              'fa fa-check': option.correct_answer && option.user_answer,
                              'fas fa-times': !option.correct_answer && option.user_answer
                            }"
                          ></i>
                          {{ option.value }}
                        </li>
                      </ul>
                    </div>
            
                    <!-- Multi-Select Question -->
                    <div *ngIf="slide.questionType === 'multiSelect'" class="question-type">
                      <p>
                        {{ i + 1 }}. {{ slide.mainQuestion.question }}
                        <span *ngIf="slide?.answerStatus == 1" class="correct">(Correct)</span>
                        <span *ngIf="slide?.answerStatus == 2" class="incorrect">(Incorrect)</span>
                      </p>
                      <ul>
                        <li
                          *ngFor="let option of slide.multiSelect"
                          [ngClass]="{
                            'correct': option.correct_answer,
                            'incorrect': !option.correct_answer && option.user_answer
                          }"
                        >
                          <i
                            [ngClass]="{
                              'fa fa-check': option.correct_answer && option.user_answer,
                              'fas fa-times': !option.correct_answer && option.user_answer
                            }"
                          ></i>
                          {{ option.label }}
                        </li>
                      </ul>
                    </div>
            
                    <!-- Horizontal Scale Question -->
                    <div *ngIf="slide.questionType === 'horizontalScale'" class="question-type">
                      <p>{{ i + 1 }}. {{ slide?.mainQuestion?.question }}</p>
                      <div class="horizontal-scale">
                        <div
                          *ngFor="let answer of slide.horizontalScale; let b = index"
                          class="scale-option"
                        >
                          <span>{{ answer.label }}</span>
                          <span *ngIf="answer?.enableNumberLabels">{{ b + 1 }}</span>
                          <input
                            type="radio"
                            [disabled]="true"
                            [checked]="answer.user_answer"
                          />
                        </div>
                      </div>
                    </div>
            
                    <!-- Free-Form Question -->
                    <div *ngIf="slide.questionType === 'freeFormQuestion'" class="question-type">
                      <p>{{ i + 1 }}. {{ slide?.mainQuestion?.question }}</p>
                      <p class="user-answer">Answer: {{ slide.mainQuestion.userAnswer }}</p>
                    </div>
                    <div *ngIf="slide?.commentsFromConfiguration?.userAnswer">
                      <p>{{ slide?.commentsFromConfiguration?.question }}</p>
                      <p>Answer: {{ slide.commentsFromConfiguration.userAnswer }}</p>
                    </div>
                  </div>
                </div>
              </nz-tab>
            </nz-tabset>
          </div>


          <!-- <div class="right-align">
            <b>Grade:</b> 
            <span *ngIf="course?.globalConfigs.correctIncorrectAnswerExist">{{quizAttempts[attemptIndex]?.Grade | number:'1.0-0'}}{{'% '}}</span> 
            <span *ngIf="course?.globalConfigs.correctIncorrectAnswerExist == false">{{quizAttempts[attemptIndex]?.ScoredPoints}}/{{quizAttempts[attemptIndex]?.TotalPoints}}</span>
            <span *ngIf="course?.globalConfigs?.isTherePassingGrade">
              {{quizAttempts[attemptIndex]?.Status}}</span> {{' '}}
              <b>Attempts:</b>
              <span class="clickable" 
              [ngClass]="{'correct-answer': attemptIndex == idx}"
              (click)="updateAttemptIndex(idx)" *ngFor="let quizAttempt of quizAttempts; let idx = index">{{idx + 1}}</span>
          </div>
          <div *ngFor="let slide of quizAttempts[attemptIndex].QuizResult; let i = index">
            <div *ngIf="slide.questionType === 'multiChoice'">
              <p>{{ i + 1 }}.<span *ngIf="slide?.isQuestionMandatoryForQssp == false">(Optional)</span>
                {{ slide.mainQuestion.question}}
                <span class="correct-answer" *ngIf="slide?.answerStatus == 1">(Pass)</span>
                <span class="wrong-answer" *ngIf="slide?.answerStatus == 2">(Fail)</span>
              </p>
              <ul>
                <li *ngFor="let option of slide.multiChoice" [ngClass]="{'correct-answer': option.correct_answer, 'wrong-answer': !option.correct_answer && option.user_answer}">
                  <i [ngClass]="{'fa fa-check': option.correct_answer && option.user_answer, 'fas fa-times': !option.correct_answer && option.user_answer}"></i> {{' '}}
                  {{ option.value }}
                </li>
              </ul>
            </div>
            <div *ngIf="slide.questionType === 'multiSelect'">
              <p>
                {{ i + 1 }}. <span *ngIf="slide?.isQuestionMandatoryForQssp == false">(Optional)</span>
                {{ slide.mainQuestion.question }}
                <span class="correct-answer" *ngIf="slide?.answerStatus == 1">(Pass)</span>
                <span class="wrong-answer" *ngIf="slide?.answerStatus == 2">(Fail)</span>
              </p>
              <ul>
                <li *ngFor="let option of slide.multiSelect" [ngClass]="{'correct-answer': option.correct_answer, 'wrong-answer': !option.correct_answer && option.user_answer}">
                  <i [ngClass]="{'fa fa-check': option.correct_answer && option.user_answer, 'fas fa-times': !option.correct_answer && option.user_answer}"></i> {{' '}}
                  {{ option.label }}
                </li>
              </ul>
            </div>
            <div *ngIf="slide.questionType === 'horizontalScale'">
              <p>{{ i + 1 }}.<span *ngIf="slide?.isQuestionMandatoryForQssp == false">(Optional)</span>  {{ slide?.mainQuestion?.question }}</p>
              <div class="horizontal-scale-container">
                <ul class="horizontal-scale-answer-list">
                  <li class="horizontal-scale-answer-item" *ngFor="let answer of slide.horizontalScale; let b = index">
                    <label class="horizontal-scale-label">{{ answer.label || " " }}</label>
                    <label class="horizontal-scale-label" *ngIf="answer?.enableNumberLabels">{{ b + 1 }}</label>
                    <input 
                      type="radio"
                      name="radio_group_{{i}}"
                      [disabled]="true"
                      [value]="answer.user_answer" 
                      [checked]="answer.user_answer"
                      class="horizontal-scale-custom-radio custom-radio"> 
                  </li>
                </ul>
              </div>
            </div>
            <div *ngIf="slide?.questionType === 'freeFormQuestion'">
              <p>{{ i + 1 }}. <span *ngIf="slide?.isQuestionMandatoryForQssp == false">(Optional)</span> {{ slide?.mainQuestion?.question }}</p>
              <p>Answer: {{ slide.mainQuestion.userAnswer }}</p>
            </div>
            <div *ngIf="slide?.commentsFromConfiguration?.userAnswer">
              <p>{{ slide?.commentsFromConfiguration?.question }}</p>
              <p>Answer: {{ slide.commentsFromConfiguration.userAnswer }}</p>  
            </div>
          </div> -->
        </div>
      </div>
    </div>
  </div>
</div>